export const estados = [
    {
        'uf': 'RO',
        'nome': 'Rondônia'
    },
    {
        'uf': 'AC',
        'nome': 'Acre'
    },
    {
        'uf': 'AM',
        'nome': 'Amazonas'
    },
    {
        'uf': 'RR',
        'nome': 'Roraima'
    },
    {
        'uf': 'PA',
        'nome': 'Pará'
    },
    {
        'uf': 'AP',
        'nome': 'Amapá'
    },
    {
        'uf': 'TO',
        'nome': 'Tocantins'
    },
    {
        'uf': 'MA',
        'nome': 'Maranhão'
    },
    {
        'uf': 'PI',
        'nome': 'Piauí'
    },
    {
        'uf': 'CE',
        'nome': 'Ceará'
    },
    {
        'uf': 'RN',
        'nome': 'Rio Grande do Norte'
    },
    {
        'uf': 'PB',
        'nome': 'Paraíba'
    },
    {
        'uf': 'PE',
        'nome': 'Pernambuco'
    },
    {
        'uf': 'AL',
        'nome': 'Alagoas'
    },
    {
        'uf': 'SE',
        'nome': 'Sergipe'
    },
    {
        'uf': 'BA',
        'nome': 'Bahia'
    },
    {
        'uf': 'MG',
        'nome': 'Minas Gerais'
    },
    {
        'uf': 'ES',
        'nome': 'Espírito Santo'
    },
    {
        'uf': 'RJ',
        'nome': 'Rio de Janeiro'
    },
    {
        'uf': 'SP',
        'nome': 'São Paulo'
    },
    {
        'uf': 'PR',
        'nome': 'Paraná'
    },
    {
        'uf': 'SC',
        'nome': 'Santa Catarina'
    },
    {
        'uf': 'RS',
        'nome': 'Rio Grande do Sul'
    },
    {
        'uf': 'MS',
        'nome': 'Mato Grosso do Sul'
    },
    {
        'uf': 'MT',
        'nome': 'Mato Grosso'
    },
    {
        'uf': 'GO',
        'nome': 'Goiás'
    },
    {
        'uf': 'DF',
        'nome': 'Distrito Federal'
    }
];

export const diasSemana = [
    {label: 'Dom', value: 'SUNDAY'},
    {label: 'Seg', value: 'MONDAY'},
    {label: 'Ter', value: 'TUESDAY'},
    {label: 'Qua', value: 'WEDNESDAY'},
    {label: 'Qui', value: 'THURSDAY'},
    {label: 'Sex', value: 'FRIDAY'},
    {label: 'Sab', value: 'SATURDAY'},

];

export const tiposAjustes = [{label: 'Desconto', value: 'DESCONTO'}, {label: 'Acréscimo', value: 'ACRESCIMO'}];

export const tiposAndares = [{
    label: '1º Andar',
    value: 1
}, {
    label: '2º Andar',
    value: 2
}];

export const tiposConfiguracoesPreco = [
    {label: 'Normal', value: 'NORMAL'},
    {label: 'A cada número de poltronas vendidas', value: 'POLTRONAS_VENDIDAS_A_CADA'},
    {label: 'A partir do número de poltronas vendidas', value: 'POLTRONAS_VENDIDAS_A_PARTIR'},
    {label: 'Hora/minuto antes da data de partida', value: 'DATA_PARTIDA'}];



export const tipoAjusteItinerario = [{label: 'Desconto', value: 'DESCONTO'}, {label: 'Acréscimo', value: 'ACRESCIMO'}];

export const tipoSituacaoTransmissaoBpe = [
    {chave: 'ERRO', valor: 'Erro no envio do BPE'},
    {chave: 'SUCESSO', valor: 'Transmitido com sucesso'},
    {chave: 'NAO_HABILITADO', valor: 'Emissor não habilitado para emissão do BP-e'},
    {chave: 'SEM_CERTIFICADO_UF', valor: 'Sem certificado na uf de origem'}
]

export const camposTipoChavePix = [
    { chave: 'CPF_CNPJ', valor: 'CPF ou CNPJ' },
    { chave: 'NUMERO_TELEFONE_CELULAR', valor:'Número De Telefone Celular'},
    { chave: 'E_MAIL',  valor: 'E-mail' },
    { chave: 'CHAVE_ALEATORIA', valor: 'Chave Aleatória'}
];

export const tipoCustoItinerario = [
    { label: 'ENTRADA', value: 'ENTRADA'},
    { label: 'SAIDA', value: 'SAIDA'},
]

export const paisesDDI = [
    { id: 1, pais: 'Afeganistão', ddi: '+93', sigla: 'AFG' },
    { id: 2, pais: 'África do Sul', ddi: '+27', sigla: 'ZAF' },
    { id: 3, pais: 'Albânia', ddi: '+355', sigla: 'ALB' },
    { id: 4, pais: 'Alemanha', ddi: '+49', sigla: 'DEU' },
    { id: 5, pais: 'Andorra', ddi: '+376', sigla: 'AND' },
    { id: 6, pais: 'Angola', ddi: '+244', sigla: 'AGO' },
    { id: 7, pais: 'Anguila', ddi: '+1-264', sigla: 'AIA' },
    { id: 8, pais: 'Antártida', ddi: '+672', sigla: 'ATA' },
    { id: 9, pais: 'Antígua e Barbuda', ddi: '+1-268', sigla: 'ATG' },
    { id: 10, pais: 'Arábia Saudita', ddi: '+966', sigla: 'SAU' },
    { id: 11, pais: 'Argélia', ddi: '+213', sigla: 'DZA' },
    { id: 12, pais: 'Argentina', ddi: '+54', sigla: 'ARG' },
    { id: 13, pais: 'Armênia', ddi: '+374', sigla: 'ARM' },
    { id: 14, pais: 'Aruba', ddi: '+297', sigla: 'ABW' },
    { id: 15, pais: 'Austrália', ddi: '+61', sigla: 'AUS' },
    { id: 16, pais: 'Áustria', ddi: '+43', sigla: 'AUT' },
    { id: 17, pais: 'Azerbaijão', ddi: '+994', sigla: 'AZE' },
    { id: 18, pais: 'Bahamas', ddi: '+1-242', sigla: 'BHS' },
    { id: 19, pais: 'Bahrein', ddi: '+973', sigla: 'BHR' },
    { id: 20, pais: 'Bangladesh', ddi: '+880', sigla: 'BGD' },
    { id: 21, pais: 'Barbados', ddi: '+1-246', sigla: 'BRB' },
    { id: 22, pais: 'Belize', ddi: '+501', sigla: 'BLZ' },
    { id: 23, pais: 'Benin', ddi: '+229', sigla: 'BEN' },
    { id: 24, pais: 'Bermudas', ddi: '+1-441', sigla: 'BMU' },
    { id: 25, pais: 'Bielorrússia', ddi: '+375', sigla: 'BLR' },
    { id: 26, pais: 'Bolívia', ddi: '+591', sigla: 'BOL' },
    { id: 27, pais: 'Bósnia e Herzegovina', ddi: '+387', sigla: 'BIH' },
    { id: 28, pais: 'Botsuana', ddi: '+267', sigla: 'BWA' },
    { id: 29, pais: 'Burkina Faso', ddi: '+226', sigla: 'BFA' },
    { id: 30, pais: 'Brunei', ddi: '+673', sigla: 'BRN' },
    { id: 31, pais: 'Bulgária', ddi: '+359', sigla: 'BGR' },  
    { id: 32, pais: 'Brasil', ddi: '+55', sigla: 'BRA' },
    { id: 33, pais: 'Burundi', ddi: '+257', sigla: 'BDI' },
    { id: 34, pais: 'Butão', ddi: '+975', sigla: 'BTN' },
    { id: 35, pais: 'Cabo Verde', ddi: '+238', sigla: 'CPV' },
    { id: 36, pais: 'Camarões', ddi: '+237', sigla: 'CMR' },
    { id: 37, pais: 'Camboja', ddi: '+855', sigla: 'KHM' },
    { id: 38, pais: 'Canadá', ddi: '+1', sigla: 'CAN' },
    { id: 39, pais: 'Catar', ddi: '+974', sigla: 'QAT' },
    { id: 40, pais: 'Cazaquistão', ddi: '+7', sigla: 'KAZ' },
    { id: 41, pais: 'Ceará', ddi: '+55', sigla: 'CE' },
    { id: 42, pais: 'Chade', ddi: '+235', sigla: 'TCD' },
    { id: 43, pais: 'Chile', ddi: '+56', sigla: 'CHL' },
    { id: 44, pais: 'China', ddi: '+86', sigla: 'CHN' },
    { id: 45, pais: 'Chipre', ddi: '+357', sigla: 'CYP' },
    { id: 46, pais: 'Colômbia', ddi: '+57', sigla: 'COL' },
    { id: 47, pais: 'Comores', ddi: '+269', sigla: 'COM' },
    { id: 48, pais: 'Congo', ddi: '+242', sigla: 'COG' },
    { id: 49, pais: 'Congo (República Democrática)', ddi: '+243', sigla: 'COD' },
    { id: 50, pais: 'Coréia do Norte', ddi: '+850', sigla: 'PRK' },
    { id: 51, pais: 'Coréia do Sul', ddi: '+82', sigla: 'KOR' },
    { id: 52, pais: 'Costa do Marfim', ddi: '+225', sigla: 'CIV' },
    { id: 53, pais: 'Costa Rica', ddi: '+506', sigla: 'CRI' },
    { id: 54, pais: 'Croácia', ddi: '+385', sigla: 'HRV' },
    { id: 55, pais: 'Cuba', ddi: '+53', sigla: 'CUB' },
    { id: 56, pais: 'Curaçao', ddi: '+599', sigla: 'CUW' },
    { id: 57, pais: 'Cyprus', ddi: '+357', sigla: 'CYP' },
    { id: 58, pais: 'República Checa', ddi: '+420', sigla: 'CZE' },
    { id: 59, pais: 'Dinamarca', ddi: '+45', sigla: 'DNK' },
    { id: 60, pais: 'Djibouti', ddi: '+253', sigla: 'DJI' },
    { id: 61, pais: 'Dominica', ddi: '+1-767', sigla: 'DMA' },
    { id: 62, pais: 'República Dominicana', ddi: '+1-809', sigla: 'DOM' },
    { id: 63, pais: 'Ecuador', ddi: '+593', sigla: 'ECU' },
    { id: 64, pais: 'Egito', ddi: '+20', sigla: 'EGY' },
    { id: 65, pais: 'El Salvador', ddi: '+503', sigla: 'SLV' },
    { id: 66, pais: 'Emirados Árabes Unidos', ddi: '+971', sigla: 'ARE' },
    { id: 67, pais: 'Equador', ddi: '+593', sigla: 'ECU' },
    { id: 68, pais: 'Eslováquia', ddi: '+421', sigla: 'SVK' },
    { id: 69, pais: 'Eslovênia', ddi: '+386', sigla: 'SVN' },
    { id: 70, pais: 'Espanha', ddi: '+34', sigla: 'ESP' },
    { id: 71, pais: 'Estados Unidos', ddi: '+1', sigla: 'USA' },
    { id: 72, pais: 'Estônia', ddi: '+372', sigla: 'EST' },
    { id: 73, pais: 'Eswatini', ddi: '+268', sigla: 'SWZ' },
    { id: 74, pais: 'Etiópia', ddi: '+251', sigla: 'ETH' },
    { id: 75, pais: 'Fiji', ddi: '+679', sigla: 'FJI' },
    { id: 76, pais: 'Filipinas', ddi: '+63', sigla: 'PHL' },
    { id: 77, pais: 'Finlândia', ddi: '+358', sigla: 'FIN' },
    { id: 78, pais: 'França', ddi: '+33', sigla: 'FRA' },
    { id: 79, pais: 'Gabão', ddi: '+241', sigla: 'GAB' },
    { id: 80, pais: 'Gâmbia', ddi: '+220', sigla: 'GMB' },
    { id: 81, pais: 'Gana', ddi: '+233', sigla: 'GHA' },
    { id: 82, pais: 'Geórgia', ddi: '+995', sigla: 'GEO' },
    { id: 83, pais: 'Gibraltar', ddi: '+350', sigla: 'GIB' },
    { id: 84, pais: 'Grécia', ddi: '+30', sigla: 'GRC' },
    { id: 85, pais: 'Grenada', ddi: '+1-473', sigla: 'GRD' },
    { id: 86, pais: 'Groelândia', ddi: '+299', sigla: 'GRL' },
    { id: 87, pais: 'Guadalupe', ddi: '+590', sigla: 'GLP' },
    { id: 88, pais: 'Guam', ddi: '+1-671', sigla: 'GUM' },
    { id: 89, pais: 'Guatemala', ddi: '+502', sigla: 'GTM' },
    { id: 90, pais: 'Guiana', ddi: '+592', sigla: 'GUY' },
    { id: 91, pais: 'Guiana Francesa', ddi: '+594', sigla: 'GUF' },
    { id: 92, pais: 'Gâmbia', ddi: '+220', sigla: 'GMB' },
    { id: 93, pais: 'Haiti', ddi: '+509', sigla: 'HTI' },
    { id: 94, pais: 'Honduras', ddi: '+504', sigla: 'HND' },
    { id: 95, pais: 'Hungria', ddi: '+36', sigla: 'HUN' },
    { id: 96, pais: 'Iémen', ddi: '+967', sigla: 'YEM' },
    { id: 97, pais: 'Ilhas Cayman', ddi: '+1-345', sigla: 'CYM' },
    { id: 98, pais: 'Ilhas Cook', ddi: '+682', sigla: 'COK' },
    { id: 99, pais: 'Ilhas Faroe', ddi: '+298', sigla: 'FRO' },
    { id: 100, pais: 'Ilhas Malvinas', ddi: '+500', sigla: 'FLK' },
    { id: 101, pais: 'Ilhas Marshall', ddi: '+692', sigla: 'MHL' },
    { id: 102, pais: 'Ilhas Pitcairn', ddi: '+872', sigla: 'PCN' },
    { id: 103, pais: 'Ilhas Salomão', ddi: '+677', sigla: 'SLB' },
    { id: 104, pais: 'Ilhas Virgens (EUA)', ddi: '+1-340', sigla: 'VIR' },
    { id: 105, pais: 'Ilhas Virgens (Reino Unido)', ddi: '+1-284', sigla: 'VGB' },
    { id: 106, pais: 'Índia', ddi: '+91', sigla: 'IND' },
    { id: 107, pais: 'Indonésia', ddi: '+62', sigla: 'IDN' },
    { id: 108, pais: 'Irã', ddi: '+98', sigla: 'IRN' },
    { id: 109, pais: 'Iraque', ddi: '+964', sigla: 'IRQ' },
    { id: 110, pais: 'Irlanda', ddi: '+353', sigla: 'IRL' },
    { id: 111, pais: 'Islândia', ddi: '+354', sigla: 'ISL' },
    { id: 112, pais: 'Israel', ddi: '+972', sigla: 'ISR' },
    { id: 113, pais: 'Itália', ddi: '+39', sigla: 'ITA' },
    { id: 114, pais: 'Jamaica', ddi: '+1-876', sigla: 'JAM' },
    { id: 115, pais: 'Japão', ddi: '+81', sigla: 'JPN' },
    { id: 116, pais: 'Jordânia', ddi: '+962', sigla: 'JOR' },
    { id: 117, pais: 'Kiribati', ddi: '+686', sigla: 'KIR' },
    { id: 118, pais: 'Kosovo', ddi: '+383', sigla: 'KSV' },
    { id: 119, pais: 'Kuwait', ddi: '+965', sigla: 'KWT' },
    { id: 120, pais: 'Laos', ddi: '+856', sigla: 'LAO' },
    { id: 121, pais: 'Lesoto', ddi: '+266', sigla: 'LSO' },
    { id: 122, pais: 'Letônia', ddi: '+371', sigla: 'LVA' },
    { id: 123, pais: 'Libéria', ddi: '+231', sigla: 'LBR' },
    { id: 124, pais: 'Líbano', ddi: '+961', sigla: 'LBN' },
    { id: 125, pais: 'Libéria', ddi: '+231', sigla: 'LBR' },
    { id: 126, pais: 'Lituânia', ddi: '+370', sigla: 'LTU' },
    { id: 127, pais: 'Luxemburgo', ddi: '+352', sigla: 'LUX' },
    { id: 128, pais: 'Macau', ddi: '+853', sigla: 'MAC' },
    { id: 129, pais: 'Madagáscar', ddi: '+261', sigla: 'MDG' },
    { id: 130, pais: 'Malásia', ddi: '+60', sigla: 'MYS' },
    { id: 131, pais: 'Malawi', ddi: '+265', sigla: 'MWI' },
    { id: 132, pais: 'Maldivas', ddi: '+960', sigla: 'MDV' },
    { id: 133, pais: 'Mali', ddi: '+223', sigla: 'MLI' },
    { id: 134, pais: 'Malta', ddi: '+356', sigla: 'MLT' },
    { id: 135, pais: 'Marianas do Norte', ddi: '+1-670', sigla: 'MNP' },
    { id: 136, pais: 'Marrocos', ddi: '+212', sigla: 'MAR' },
    { id: 137, pais: 'Maurício', ddi: '+230', sigla: 'MUS' },
    { id: 138, pais: 'Mauritânia', ddi: '+222', sigla: 'MRT' },
    { id: 139, pais: 'México', ddi: '+52', sigla: 'MEX' },
    { id: 140, pais: 'Mianmar', ddi: '+95', sigla: 'MMR' },
    { id: 141, pais: 'Micronésia', ddi: '+691', sigla: 'FSM' },
    { id: 142, pais: 'Moçambique', ddi: '+258', sigla: 'MOZ' },
    { id: 143, pais: 'Moldávia', ddi: '+373', sigla: 'MDA' },
    { id: 144, pais: 'Mônaco', ddi: '+377', sigla: 'MCO' },
    { id: 145, pais: 'Montenegro', ddi: '+382', sigla: 'MNE' },
    { id: 146, pais: 'Montserrat', ddi: '+1-664', sigla: 'MSR' },
    { id: 147, pais: 'Namíbia', ddi: '+264', sigla: 'NAM' },
    { id: 148, pais: 'Nauru', ddi: '+674', sigla: 'NRU' },
    { id: 149, pais: 'Nepal', ddi: '+977', sigla: 'NPL' },
    { id: 150, pais: 'Nicarágua', ddi: '+505', sigla: 'NIC' },
    { id: 151, pais: 'Níger', ddi: '+227', sigla: 'NER' },
    { id: 152, pais: 'Nigéria', ddi: '+234', sigla: 'NGA' },
    { id: 153, pais: 'Niue', ddi: '+683', sigla: 'NIU' },
    { id: 154, pais: 'Noruega', ddi: '+47', sigla: 'NOR' },
    { id: 155, pais: 'Nova Caledônia', ddi: '+687', sigla: 'NCL' },
    { id: 156, pais: 'Nova Zelândia', ddi: '+64', sigla: 'NZL' },
    { id: 157, pais: 'Omã', ddi: '+968', sigla: 'OMN' },
    { id: 158, pais: 'Paquistão', ddi: '+92', sigla: 'PAK' },
    { id: 159, pais: 'Palau', ddi: '+680', sigla: 'PLW' },
    { id: 160, pais: 'Panamá', ddi: '+507', sigla: 'PAN' },
    { id: 161, pais: 'Papua-Nova Guiné', ddi: '+675', sigla: 'PNG' },
    { id: 162, pais: 'Paraguai', ddi: '+595', sigla: 'PRY' },
    { id: 163, pais: 'Peru', ddi: '+51', sigla: 'PER' },
    { id: 164, pais: 'Polônia', ddi: '+48', sigla: 'POL' },
    { id: 165, pais: 'Portugal', ddi: '+351', sigla: 'PRT' },
    { id: 166, pais: 'Quênia', ddi: '+254', sigla: 'KEN' },
    { id: 167, pais: 'Quiribati', ddi: '+686', sigla: 'KIR' },
    { id: 168, pais: 'Reino Unido', ddi: '+44', sigla: 'GBR' },
    { id: 169, pais: 'República Centro-Africana', ddi: '+236', sigla: 'CAF' },
    { id: 170, pais: 'República Dominicana', ddi: '+1-809', sigla: 'DOM' },
    { id: 171, pais: 'República do Congo', ddi: '+242', sigla: 'COG' },
    { id: 172, pais: 'República Tcheca', ddi: '+420', sigla: 'CZE' },
    { id: 173, pais: 'Reunião', ddi: '+262', sigla: 'REU' },
    { id: 174, pais: 'Romênia', ddi: '+40', sigla: 'ROU' },
    { id: 175, pais: 'Ruanda', ddi: '+250', sigla: 'RWA' },
    { id: 176, pais: 'Rússia', ddi: '+7', sigla: 'RUS' },
    { id: 177, pais: 'Saara Ocidental', ddi: '+212', sigla: 'ESH' },
    { id: 178, pais: 'Samoa', ddi: '+685', sigla: 'WSM' },
    { id: 179, pais: 'San Marino', ddi: '+378', sigla: 'SMR' },
    { id: 180, pais: 'Santa Helena', ddi: '+290', sigla: 'SHN' },
    { id: 181, pais: 'Senegal', ddi: '+221', sigla: 'SEN' },
    { id: 182, pais: 'Serra Leoa', ddi: '+232', sigla: 'SLE' },
    { id: 183, pais: 'Seychelles', ddi: '+248', sigla: 'SYC' },
    { id: 184, pais: 'Singapura', ddi: '+65', sigla: 'SGP' },
    { id: 185, pais: 'Síria', ddi: '+963', sigla: 'SYR' },
    { id: 186, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 187, pais: 'Sri Lanka', ddi: '+94', sigla: 'LKA' },
    { id: 188, pais: 'Suazilândia', ddi: '+268', sigla: 'SWZ' },
    { id: 189, pais: 'Sudão', ddi: '+249', sigla: 'SDN' },
    { id: 190, pais: 'Sudão do Sul', ddi: '+211', sigla: 'SSD' },
    { id: 191, pais: 'Suriname', ddi: '+597', sigla: 'SUR' },
    { id: 192, pais: 'Suécia', ddi: '+46', sigla: 'SWE' },
    { id: 193, pais: 'Suiça', ddi: '+41', sigla: 'CHE' },
    { id: 194, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 195, pais: 'São Cristóvão e Nevis', ddi: '+1-869', sigla: 'KNA' },
    { id: 196, pais: 'São Marino', ddi: '+378', sigla: 'SMR' },
    { id: 197, pais: 'São Tomé e Príncipe', ddi: '+239', sigla: 'STP' },
    { id: 198, pais: 'Senegal', ddi: '+221', sigla: 'SEN' },
    { id: 199, pais: 'Serra Leoa', ddi: '+232', sigla: 'SLE' },
    { id: 200, pais: 'Seychelles', ddi: '+248', sigla: 'SYC' },
    { id: 201, pais: 'Singapura', ddi: '+65', sigla: 'SGP' },
    { id: 202, pais: 'Síria', ddi: '+963', sigla: 'SYR' },
    { id: 203, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 204, pais: 'Sri Lanka', ddi: '+94', sigla: 'LKA' },
    { id: 205, pais: 'Suazilândia', ddi: '+268', sigla: 'SWZ' },
    { id: 206, pais: 'Sudão', ddi: '+249', sigla: 'SDN' },
    { id: 207, pais: 'Sudão do Sul', ddi: '+211', sigla: 'SSD' },
    { id: 208, pais: 'Suriname', ddi: '+597', sigla: 'SUR' },
    { id: 209, pais: 'Suécia', ddi: '+46', sigla: 'SWE' },
    { id: 210, pais: 'Suiça', ddi: '+41', sigla: 'CHE' },
    { id: 211, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 212, pais: 'São Cristóvão e Nevis', ddi: '+1-869', sigla: 'KNA' },
    { id: 213, pais: 'São Marino', ddi: '+378', sigla: 'SMR' },
    { id: 214, pais: 'São Tomé e Príncipe', ddi: '+239', sigla: 'STP' },
    { id: 215, pais: 'Senegal', ddi: '+221', sigla: 'SEN' },
    { id: 216, pais: 'Serra Leoa', ddi: '+232', sigla: 'SLE' },
    { id: 217, pais: 'Seychelles', ddi: '+248', sigla: 'SYC' },
    { id: 218, pais: 'Singapura', ddi: '+65', sigla: 'SGP' },
    { id: 219, pais: 'Síria', ddi: '+963', sigla: 'SYR' },
    { id: 220, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 221, pais: 'Sri Lanka', ddi: '+94', sigla: 'LKA' },
    { id: 222, pais: 'Suazilândia', ddi: '+268', sigla: 'SWZ' },
    { id: 223, pais: 'Sudão', ddi: '+249', sigla: 'SDN' },
    { id: 224, pais: 'Sudão do Sul', ddi: '+211', sigla: 'SSD' },
    { id: 225, pais: 'Suriname', ddi: '+597', sigla: 'SUR' },
    { id: 226, pais: 'Suécia', ddi: '+46', sigla: 'SWE' },
    { id: 227, pais: 'Suiça', ddi: '+41', sigla: 'CHE' },
    { id: 228, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 229, pais: 'São Cristóvão e Nevis', ddi: '+1-869', sigla: 'KNA' },
    { id: 230, pais: 'São Marino', ddi: '+378', sigla: 'SMR' },
    { id: 231, pais: 'São Tomé e Príncipe', ddi: '+239', sigla: 'STP' },
    { id: 232, pais: 'Senegal', ddi: '+221', sigla: 'SEN' },
    { id: 233, pais: 'Serra Leoa', ddi: '+232', sigla: 'SLE' },
    { id: 234, pais: 'Seychelles', ddi: '+248', sigla: 'SYC' },
    { id: 235, pais: 'Singapura', ddi: '+65', sigla: 'SGP' },
    { id: 236, pais: 'Síria', ddi: '+963', sigla: 'SYR' },
    { id: 237, pais: 'Somália', ddi: '+252', sigla: 'SOM' },
    { id: 238, pais: 'Sri Lanka', ddi: '+94', sigla: 'LKA' },
    { id: 239, pais: 'Suazilândia', ddi: '+268', sigla: 'SWZ' },
    { id: 240, pais: 'Sudão', ddi: '+249', sigla: 'SDN' },
    { id: 241, pais: 'Sudão do Sul', ddi: '+211', sigla: 'SSD' },
    { id: 242, pais: 'Suriname', ddi: '+597', sigla: 'SUR' },
    { id: 243, pais: 'Suécia', ddi: '+46', sigla: 'SWE' },
    { id: 244, pais: 'Suiça', ddi: '+41', sigla: 'CHE' }
    ];




